
import { defineComponent } from "vue";

import Notes from "@/components/document-crdt/Notes.vue";
import Document from "@/components/document-crdt/Document.vue";

export default defineComponent({
  name: "Reverse Engineering Yjs",
  components: {
    Document,
    Notes,
  },
  data() {
    return {
      ids: ["Alpha", "Bravo", "Charlie", "Delta", "Echo"],
      measure: () => {
        //
      },
    };
  },
  mounted: function () {
    this.measure = (this.$refs["notes"] as any).measure;
  },
  methods: {
    documents: function () {
      const docs = {} as any;
      this.ids.forEach((id: string) => {
        docs[id] = (this.$refs[id] as any)[0];
      });
      return docs;
    },
  },
});
