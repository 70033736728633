
import { defineComponent } from "vue";

import * as Y from "yjs";
import { yDocToProsemirror } from "y-prosemirror";
import { EditorState, Plugin } from "prosemirror-state";
import { EditorView } from "prosemirror-view";
import schema from "../prosemirror/schema";

import { SyncPluginKey } from "./sync";
import { getStateVector } from "./helpers";

export default defineComponent({
  name: "Notes",
  props: {
    documents: {
      type: Function,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pullFrom: "",
      pullInto: "",
      versionRender: null as any,
      measurements: {
        Alpha: {},
        Bravo: {},
        Charlie: {},
        Delta: {},
        Echo: {},
      } as any,
    };
  },
  mounted: function () {
    this.ids.forEach((id: any) => {
      this.init(id);
    });
  },
  methods: {
    /* Logging */
    logDoc: function (id: string) {
      console.log(`${id}:`, this.documents()[id].getDoc());
    },
    logView: function (id: string) {
      console.log(`${id}:`, this.documents()[id].getView());
    },
    logSnapshot: function (id: string) {
      console.log(`${id}:`, Y.snapshot(this.documents()[id].getDoc()));
    },
    renderPruning: function () {
      const filteredDeleteSet = { clients: new Map() };
      Array.from(
        this.measurements["Alpha"]["delete-set"].clients.keys()
      ).forEach((client) => {
        filteredDeleteSet.clients.set(
          client,
          this.measurements["Alpha"]["delete-set"].clients
            .get(client)
            .filter(
              (item: any, i: number) =>
                this.measurements["Alpha"]["snapshot-delete-set"][
                  client as any
                ][i]
            )
        );
      });
      const snapshot = new Y.Snapshot(
        filteredDeleteSet,
        this.measurements["Alpha"]["snapshot-clock"]
      );
      //console.log(snapshot);
      if (
        Array.from(snapshot.sv).reduce(
          (acc, client) => acc && client[1] > 0,
          true
        )
      ) {
        const doc = Y.createDocFromSnapshot(
          this.documents()["Alpha"].getDoc(),
          snapshot
        );
        if (this.versionRender != null) this.versionRender.destroy();
        const rendering = yDocToProsemirror(schema, doc);
        //console.log(rendering);

        const state = EditorState.create({ schema: schema, doc: rendering });
        this.versionRender = new EditorView(this.$refs["snapshot"] as any, {
          state,
          plugins: [
            new Plugin({
              props: {
                editable: () => {
                  return false;
                },
              },
            }),
          ],
        });
      }
    },

    /* State */
    init: function (id: string) {
      const doc = this.documents()[id].getDoc();
      const version = Array.from(doc.store.clients.keys()).reduce(
        (clock: any, timeline: any) => {
          return Object.assign(clock, {
            [(this.ids as any)[timeline]]: (
              doc.store.clients.get(timeline) as any
            ).length,
          });
        },
        {}
      );
      const encodedVersion = Y.encodeStateVector(doc);
      const encodedState = Y.encodeStateAsUpdate(doc);

      this.measurements[id]["*current-version"] = version;
      this.measurements[id]["current-version"] = encodedVersion;
      this.measurements[id]["current-state"] = encodedState;
      this.measurements[id]["accepted-version"] = encodedVersion;
      this.measurements[id]["accepted-state"] = encodedState;
      this.measurements[id]["acknowledged-version"] = encodedVersion;
      this.measurements[id]["acknowledged-state"] = encodedState;

      this.measurements[id]["online"] = false;
      this.measurements[id]["passive-pull"] = true;
      this.measurements[id]["passive-stage"] = true;

      this.measurements[id]["clock"] = getStateVector(doc.store);
      this.measurements[id]["snapshot-clock"] = getStateVector(doc.store);
      this.measurements[id]["delete-set"] = Y.createDeleteSetFromStructStore(
        doc.store
      );
      this.measurements[id]["snapshot-delete-set"] =
        Y.createDeleteSetFromStructStore(doc.store);
    },
    stage: function (id: string) {
      const doc = this.documents()[id].getDoc();
      this.measurements[id]["accepted-version"] = Y.encodeStateVector(doc);
      this.measurements[id]["accepted-state"] = Y.encodeStateAsUpdate(doc);

      const update = Y.diffUpdate(
        this.measurements[id]["accepted-state"],
        this.measurements[id]["acknowledged-version"]
      );

      this.measurements[id]["acknowledged-state"] = Y.mergeUpdates([
        this.measurements[id]["acknowledged-state"],
        update,
      ]);

      this.measurements[id]["acknowledged-version"] =
        Y.encodeStateVectorFromUpdate(
          this.measurements[id]["acknowledged-state"]
        );
    },
    pull: function (into: string) {
      const updateCurrent = Y.diffUpdate(
        this.measurements[into]["acknowledged-state"],
        this.measurements[into]["current-version"]
      );
      const updateAccepted = Y.diffUpdate(
        this.measurements[into]["acknowledged-state"],
        this.measurements[into]["accepted-version"]
      );

      Y.applyUpdate(this.documents()[into].getDoc(), updateCurrent);
      this.measurements[into]["accepted-state"] = Y.mergeUpdates([
        this.measurements[into]["accepted-state"],
        updateAccepted,
      ]);
      this.measurements[into]["accepted-version"] =
        Y.encodeStateVectorFromUpdate(
          this.measurements[into]["accepted-state"]
        );

      if (this.measurements[into]["passive-stage"]) this.stage(into);
    },
    toggleOnline: function (id: string) {
      this.measurements[id]["online"] ? this.disconnect(id) : this.connect(id);
    },
    connect: function (id: string) {
      this.measurements[id]["online"] = true;
      this.ids
        .filter((peer: any) => this.measurements[peer].online && peer != id)
        .forEach((peer: any) => {
          this.applySync(
            id,
            this.getSync(
              peer,
              id,
              this.measurements[id]["acknowledged-version"]
            )
          );
        });
    },
    send: function (id: string) {
      console.log("sending");
      this.ids
        .filter((peer: any) => this.measurements[peer].online && peer != id)
        .forEach((peer: any) => {
          this.applySync(
            peer,
            this.getSync(
              id,
              peer,
              this.measurements[peer]["acknowledged-version"]
            )
          );
        });
    },
    disconnect: function (id: string) {
      this.measurements[id]["online"] = false;
    },
    getSync: function (
      from: string, // me
      into: string, // the one requesting
      version: Uint8Array, // the version of the one requesting
      confirmation = true
    ): Uint8Array {
      console.log(
        "getting sync from ",
        from,
        " for ",
        into,
        " at version ",
        version
      );
      setTimeout(() => {
        if (!confirmation) {
          this.applySync(
            from,
            this.getSync(
              into,
              from,
              this.measurements[from]["acknowledged-version"]
            )
          );
        }
      }, 0);

      return Y.diffUpdate(this.measurements[from]["accepted-state"], version);
    },
    applySync: function (into: string, update: Uint8Array) {
      console.log(
        "applying update",
        update,
        " to state ",
        this.measurements[into]["acknowledged-state"]
      );
      this.measurements[into]["acknowledged-state"] = Y.mergeUpdates([
        this.measurements[into]["acknowledged-state"],
        update,
      ]);
      this.measurements[into]["acknowledged-version"] =
        Y.encodeStateVectorFromUpdate(
          this.measurements[into]["acknowledged-state"]
        );
      if (this.measurements[into]["passive-pull"]) this.pull(into);
    },

    /* Setters */
    measure: function (id: string, doc: any) {
      // State
      const oldVersion = this.measurements[id]["*current-version"];
      this.measurements[id]["*current-version"] = Array.from(
        doc.store.clients.keys()
      ).reduce((clock: any, timeline: any) => {
        return Object.assign(clock, {
          [(this.ids as any)[timeline]]: (
            doc.store.clients.get(timeline) as any
          ).length,
        });
      }, {});
      console.log(
        !Object.keys(this.measurements[id]["*current-version"]).reduce(
          (acc, key) =>
            acc &&
            oldVersion[key] == this.measurements[id]["*current-version"][key],
          true
        )
      );
      if (
        !Object.keys(this.measurements[id]["*current-version"]).reduce(
          (acc, key) =>
            acc &&
            oldVersion[key] == this.measurements[id]["*current-version"][key],
          true
        )
      ) {
        this.measurements[id]["current-version"] = Y.encodeStateVector(doc);
        this.measurements[id]["current-state"] = Y.encodeStateAsUpdate(doc);

        // Staging
        if (this.measurements[id]["passive-stage"]) this.stage(id);

        // Clock

        this.measurements[id]["clock"] = getStateVector(doc.store);
        this.measurements[id]["snapshot-clock"] = getStateVector(doc.store);
        this.measurements[id]["delete-set"] = Y.createDeleteSetFromStructStore(
          doc.store
        );
        this.measurements[id]["snapshot-delete-set"] = Array.from(
          this.measurements[id]["delete-set"].clients.keys()
        ).map((key: any) => {
          const timeline = this.measurements[id]["delete-set"].clients.get(key);
          return timeline.map((item: any) => true);
        });
        if (id == "Alpha") this.renderPruning();
        if (
          this.measurements[id]["online"] &&
          this.measurements[id]["passive-stage"]
        )
          this.send(id);
      }
    },

    /* Global */
    pullFromTo: function (from: string, to: string) {
      console.log(`Pulling from ${from} into ${to}`);

      const fromDoc = this.documents()[from].getDoc();
      const toDoc = this.documents()[to].getDoc();

      const fromVector = Y.encodeStateVector(fromDoc);
      const toVector = Y.encodeStateVector(this.documents()[to].getDoc());

      const diff = Y.encodeStateAsUpdate(fromDoc, toVector);

      Y.applyUpdate(toDoc, diff);
    },
  },
});
